import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

const helpers = createMultiStyleConfigHelpers([
  'wrapper',
  'icon',
  'container',
  'arrow',
  'arrowContainer',
]);

export default helpers.defineMultiStyleConfig({
  baseStyle: {
    wrapper: {
      backgroundColor: '#FFF',
      boxShadow:
        '0px 8px 10px rgba(2, 3, 3, 0.03), 0px 3px 14px rgba(2, 3, 3, 0.02), 0px 5px 5px rgba(2, 3, 3, 0.04)',
      p: 2,
      flexDir: 'column',
    },
    icon: {
      mt: '6px',
      mr: 2,
    },
    coverImg: {
      h: '154px',
    },
    container: {
      mt: 2,
      p: 2,
      color: 'gray.400',
      display: 'flex',
      flexDir: 'column',
      justifyContent: 'space-between',
      w: '100%',
    },
    arrowContainer: {
      lineHeight: 8,
      justifyContent: 'end',
      display: 'grid',
      fontSize: '24px',
      pt: 5,
      px: 1,
    },
    arrow: {
      color: 'brand.200',
      mr: 1,
    },
    linkWrapper: {
      backgroundColor: 'red',
    },
  },
  variants: {
    dark: {
      wrapper: {
        backgroundColor: 'gray.900',
        color: '#FFF',
      },
      container: {
        color: '#FFF',
      },
    },
    notification: {
      wrapper: {
        borderLeftWidth: '4px',
        h: '100%',
      },
      container: {
        pl: 0,
        minH: '76px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      },
      arrow: {
        color: 'gray.900',
      },
    },
    'detailed-quotation-vhc': {
      container: {
        borderRadius: '0',
        backgroundColor: 'gray.900',
      },
    },
    'reasoned-quotation': {
      container: {
        color: '#FFF',
        borderRadius: 0,
        backgroundColor: 'gray.900',
      },
    },
    vehicle: {
      container: {
        borderRadius: 0,
        backgroundColor: 'gray.900',
      },
    },
    navigation: {
      container: {
        backgroundColor: '#FFF',
        py: 0,
      },
      arrowContainer: {
        pt: 0,
        mt: 2,
      },
      arrow: {
        color: 'gray.900',
      },
    },
    news: {
      container: {
        borderRadius: 0,
        color: '#FFF',
        backgroundColor: 'gray.900',
      },
    },
    appointment: {
      container: {
        p: 2,
        color: 'gray.900',
      },
    },
    'quick-appointment': {
      container: {
        p: 0,
      },
    },
  },
});
