import asaColor from '@asa/asasqt-microfrontend-components/dist/theme/foundations/asaColors';

import { asaServiceColors } from '@/theme/commons/foundations/asaServiceColors';

export const colors = {
  brand: asaServiceColors.red,
  secondary: asaColor.blue,
  gray: asaServiceColors.gray,
  success: asaColor.green,
  error: asaColor.darkRed,
  warning: asaColor.orange,
  transparent: asaColor.transparent,
};
