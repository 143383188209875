import { foundations as commonFoundations } from '../../commons/foundations';
import { colors } from './colors';

// TODO: import this from components

const foundations = {
  ...commonFoundations,
  colors,
};

export default foundations;
