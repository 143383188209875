export const asaServiceColors = {
  red: {
    50: '#FFEBEE',
    100: '#FFCDD3',
    200: '#F19A9B',
    300: '#E77374',
    400: '#F25251',
    500: '#F84139',
    600: '#E93838',
    700: '#D72E32',
    800: '#CA272B',
    900: '#BB191F',
  },
  gray: {
    50: '#EEF8FE',
    100: '#E3EDF4',
    200: '#D6E0E6',
    300: '#C2CCD2',
    400: '#9DA7AD',
    500: '#7C858B',
    600: '#555E64',
    700: '#434C51',
    800: '#262E33',
    900: '#010D13',
  },
  purple: {
    400: '#9C27B0',
  },
  transparent: {
    1: 'transparent',
  },
};
