const bottomMenuItemTheme = {
  baseStyle: {
    display: 'grid',
    alignItems: 'center',
    justifyItems: 'center',
    gridAutoFlow: 'row',
    py: '7px',
    fontSize: '11px',
    lineHeight: '1.45',
    textAlign: 'center',
    height: '100%',
  },
  variants: {
    default: {
      color: 'gray.500',
    },
    active: {
      color: '#FFF',
    },
  },
  defaultProps: {
    variant: 'default',
  },
};

export default bottomMenuItemTheme;
