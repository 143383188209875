import { spacing } from './spacing';

export const layout = {
  breakpoints: {
    desktop: 'md',
  },
  topBar: {
    height: {
      base: `calc(4rem + ${spacing['safe-top']})`,
      md: `calc(5.5rem + ${spacing['safe-top']})`,
    },
    bg: 'red',
  },
};
