import defaultTopBarTheme from '@asa/asasqt-microfrontend-components/dist/theme/components/topBar';

import { layout as topBarCSS } from '../foundations/layout';

const topBar = {
  baseStyle: {
    container: {
      ...defaultTopBarTheme.baseStyle?.container,
      h: topBarCSS.topBar.height,
    },
    spacePlaceholder: {
      ...defaultTopBarTheme.baseStyle?.spacePlaceholder,
      h: topBarCSS.topBar.height,
    },
  },
};

export default topBar;
