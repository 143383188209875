import { AppProps } from 'next/app';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import { Suspense } from 'react';
import { RecoilRoot } from 'recoil';

import { ChakraProvider } from '@chakra-ui/react';

import useMatomo, {
  SiteIds,
} from '@asa/asasqt-microfrontend-components/dist/hooks/useMatomo';
import { Loader } from '@asa/asasqt-microfrontend-components/dist/layout/animate/Loader';
import { Loading } from '@asa/asasqt-microfrontend-components/dist/layout/animate/Loading';
import { Viewport as LayoutViewport } from '@asa/asasqt-microfrontend-components/dist/layout/ui/Viewport';

import { APP_PREFIX } from '@/core/common/api/main/main.api';
import { FaviconsContainer } from '@/core/common/components/FaviconsContainer';
import { mitsubishi } from '@/theme/';

// TODO: remove this after css import issue is fixed
import '../core/service/appointments/request/interface/organism/tissue/styleCalendar.css';

const DynamicProviders = dynamic<React.PropsWithChildren<unknown>>(
  () => import('@/Providers').then((mod) => mod.Providers),
  {
    ssr: false,
    loading: () => <Loading />,
  }
);

const App = ({ Component, pageProps }: AppProps) => {
  const siteIds: SiteIds = {
    dev: 27,
    qs: 28,
    demo: 29,
    prod: 30,
  };

  // TODO: see in the future if we will have sensitive info to put in excludedQueryParams
  useMatomo({ siteIds, excludedQueryParams: ['code', 'token'] });

  return (
    <ChakraProvider
      theme={{
        ...mitsubishi,
      }}
    >
      <Head>
        <title>ServiceApp</title>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
        />
        <meta
          name="msapplication-config"
          content={`/${APP_PREFIX}/browserconfig.xml`}
        />
        <link rel="manifest" href={`/${APP_PREFIX}/manifest.json`} />
        <FaviconsContainer />
      </Head>
      <Suspense fallback={<Loader />}>
        <RecoilRoot>
          <DynamicProviders>
            <LayoutViewport>
              <Component {...pageProps} />
            </LayoutViewport>
          </DynamicProviders>
        </RecoilRoot>
      </Suspense>
    </ChakraProvider>
  );
};
export default App;
