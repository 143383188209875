import { colorStyles } from '@asa/asasqt-microfrontend-components/dist/theme/foundations/colorStyles.js';
import { shadows } from '@asa/asasqt-microfrontend-components/dist/theme/foundations/shadows';
import { typography } from '@asa/asasqt-microfrontend-components/dist/theme/foundations/typography';
import { zIndices } from '@asa/asasqt-microfrontend-components/dist/theme/foundations/z-index';

import { borderRadius } from './borderRadius';
import { layout } from './layout';
import { spacing } from './spacing';
import { overWrittenTextStyles } from './textStyles';

export const foundations = {
  ...typography,
  shadows,
  space: spacing,
  layout,
  zIndices,
  borderRadius,
  colorStyles,
  textStyles: overWrittenTextStyles,
};
