import androidIcon36 from '../../../../../public/android-icon-36x36.png';
import androidIcon48 from '../../../../../public/android-icon-48x48.png';
import androidIcon72 from '../../../../../public/android-icon-72x72.png';
import androidIcon96 from '../../../../../public/android-icon-96x96.png';
import androidIcon144 from '../../../../../public/android-icon-144x144.png';
import androidIcon192 from '../../../../../public/android-icon-192x192.png';
import appleIcon57 from '../../../../../public/apple-icon-57x57.png';
import appleIcon60 from '../../../../../public/apple-icon-60x60.png';
import appleIcon72 from '../../../../../public/apple-icon-72x72.png';
import appleIcon76 from '../../../../../public/apple-icon-76x76.png';
import appleIcon114 from '../../../../../public/apple-icon-114x114.png';
import appleIcon120 from '../../../../../public/apple-icon-120x120.png';
import appleIcon144 from '../../../../../public/apple-icon-144x144.png';
import appleIcon152 from '../../../../../public/apple-icon-152x152.png';
import appleIcon180 from '../../../../../public/apple-icon-180x180.png';
import appleIconPrecomposed from '../../../../../public/apple-icon-precomposed.png';
import appleIcon from '../../../../../public/apple-icon.png';
import favicon16 from '../../../../../public/favicon-16x16.png';
import favicon32 from '../../../../../public/favicon-32x32.png';
import favicon96 from '../../../../../public/favicon-96x96.png';
import favicon from '../../../../../public/favicon.ico';

export const FaviconsContainer = () => (
  <>
    {/* Favicon */}
    <link rel="icon" type="image/png" sizes="16x16" href={favicon16.src} />
    <link rel="icon" type="image/png" sizes="32x32" href={favicon32.src} />
    <link rel="icon" type="image/png" sizes="96x96" href={favicon96.src} />
    <link rel="icon" href={favicon.src} />

    {/* Apple Icons */}
    <link rel="apple-touch-icon" href={appleIcon.src} />
    <link rel="apple-touch-icon-precomposed" href={appleIconPrecomposed.src} />
    <link rel="icon" sizes="57x57" href={appleIcon57.src} />
    <link rel="icon" sizes="60x60" href={appleIcon60.src} />
    <link rel="icon" sizes="72x72" href={appleIcon72.src} />
    <link rel="icon" sizes="76x76" href={appleIcon76.src} />
    <link rel="icon" sizes="114x114" href={appleIcon114.src} />
    <link rel="icon" sizes="120x120" href={appleIcon120.src} />
    <link rel="icon" sizes="144x144" href={appleIcon144.src} />
    <link rel="icon" sizes="152x152" href={appleIcon152.src} />
    <link rel="icon" sizes="180x180" href={appleIcon180.src} />

    {/* Android Icons */}
    <link rel="icon" type="image/png" sizes="36x36" href={androidIcon36.src} />
    <link rel="icon" type="image/png" sizes="48x48" href={androidIcon48.src} />
    <link rel="icon" type="image/png" sizes="72x72" href={androidIcon72.src} />
    <link rel="icon" type="image/png" sizes="96x96" href={androidIcon96.src} />
    <link
      rel="icon"
      type="image/png"
      sizes="144x144"
      href={androidIcon144.src}
    />
    <link
      rel="icon"
      type="image/png"
      sizes="192x192"
      href={androidIcon192.src}
    />
    <link
      rel="icon"
      type="image/png"
      sizes="192x192"
      href={androidIcon192.src}
    />
  </>
);
