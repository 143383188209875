import { extendTheme } from '@chakra-ui/react';

import config from '@asa/asasqt-microfrontend-components/dist/theme/';
import { colorStyles } from '@asa/asasqt-microfrontend-components/dist/theme/foundations/colorStyles.js';
import { textStyles } from '@asa/asasqt-microfrontend-components/dist/theme/foundations/textStyles';

import * as components from '../commons/components';
import { styles } from '../styles';
import unbranded from '../unbranded';
import foundations from './foundations';

const defaultTheme = extendTheme(unbranded);

export default extendTheme(
  {
    config,
    styles,
    ...foundations,
    textStyles,
    colorStyles,
    components: { ...components },
  },
  defaultTheme
);
