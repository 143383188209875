import { extendTheme } from '@chakra-ui/react';

import config from '@asa/asasqt-microfrontend-components/dist/theme/';

import * as components from '../commons/components';
import { styles } from '../styles';
// import * as themeComponents from './components';
import foundations from './foundations';

export default extendTheme({
  config,
  styles,
  ...foundations,
  components: { ...components },
});
