import { spacing as defaultSpacing } from '@asa/asasqt-microfrontend-components/dist/theme/foundations/spacing';

export const spacing = {
  ...defaultSpacing,
  pageContent: {
    padding: {
      y: 4,
    },
  },
};
